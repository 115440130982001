import { Route } from "@solidjs/router";
import { ROUTES } from "../const";
import { EditResources } from "../views";
import { Ctrl } from ":mods";

export function AdminRoutes(props: Ctrl.Routes.model.AdminViewProps) {
  return (
    <>
      <Route path={[ROUTES.foundation()]} element={<EditResources {...props} />} />
    </>
  );
}
